.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.container p {
    color: var(--text-color);
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.2px;
}