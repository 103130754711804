.search_input {
    height: 48px;
    width: 256px;
    outline: none;
    border-radius: 8px;
    background-color: transparent;
    border-color: var(--black-60);
    margin-right: 24px;
}
.search_input input {
    background-color: transparent;
}
.search_container {
    display: flex;
    top: 30px;
    right: 12em;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
