.DatePicker {
    padding: 0;
    margin: 12px 16px;
    width: 224px;
}

.DatePicker input {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 10px;
    width: 108px;
    color: var(--primary-color);
}

.DatePicker input:first-child {
    margin-right: 8px;
}

.DatePicker input:hover {
    border: 1px solid var(--primary-color);
}

.DatePicker :global(.ant-picker-active-bar),
.DatePicker :global(.ant-picker-range-separator) {
    display: none;
}

.DatePicker:global(.ant-picker-focused) {
    box-shadow: none;
}

.DatePicker:global(.ant-picker) {
    background-color: transparent;
    border: none;
}

.Dropdown {
    z-index: 99999;
}

.Dropdown :global(.ant-picker-range-arrow) {
    display: none;
}

.Dropdown :global(.ant-picker-date-panel) {
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    padding: 16px;
    margin: 0;
    background-color: var(--white-color);
    position: absolute;
    left: 240px;
    top: -48px;
    width: 368px;
    height: auto;
}

.Dropdown :global(.ant-picker-panels .ant-picker-panel:last-child) {
    display: none;
}

.Dropdown :global(.ant-picker-panels .ant-picker-panel:first-child .ant-picker-header-next-btn),
.Dropdown :global(.ant-picker-panels .ant-picker-panel:first-child .ant-picker-header-super-next-btn) {
    visibility: visible !important;
}

.Dropdown :global(.ant-picker-header) {
    border-bottom: none;
}

.Dropdown :global(.ant-picker-content) {
    width: 100%;
}

.Dropdown :global(.ant-picker-content th) {
    font-size: 12px;
    line-height: 16px;
    color: var(--black-60);
}

.Dropdown :global(.ant-picker-date-panel .ant-picker-body) {
    padding: 0;
}

.Dropdown :global(.ant-picker-cell) {
    height: 48px;
    width: 48px;
}

.Dropdown :global(.ant-picker-cell-range-end),
.Dropdown :global(.ant-picker-cell-range-start),
.Dropdown :global(.ant-picker-cell-selected) {
    background-color: var(--primary-color);
    border-radius: 8px;
}

.Dropdown :global(.ant-picker-cell-range-end .ant-picker-cell-inner),
.Dropdown :global(.ant-picker-cell-range-start .ant-picker-cell-inner),
.Dropdown :global(.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: var(--primary-color);
}

.Dropdown :global(.ant-picker-cell .ant-picker-cell-inner) {
    font-size: 14px;
    line-height: 18px;
}

.Dropdown :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
    top: -13px;
    left: -13px;
    height: 48px;
    width: 48px;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
}

.Dropdown :global(.ant-picker-cell-in-range:before),
.Dropdown :global(.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-start-single)::before),
.Dropdown :global(.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before) {
    display: none;
}

.Dropdown :global(.ant-picker-cell-in-range) {
    background: var(--black-10);
}