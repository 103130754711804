.button_group {
    margin: -20px 0 0 13px;
}

.Checkbox,
.button_group :global(.ant-checkbox-group-item) {
    line-height: 18px;
    color: var(--black-100);
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    background-color: var(--black-10);
    border: 1px solid var(--black-40);
    border-radius: 4px;
    margin-right: 24px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.button_group :global(.ant-checkbox) {
    display: none;
}

.active,
.active:hover,
.button_group :global(.ant-checkbox-group-item:hover),
.button_group :global(.ant-checkbox-wrapper-checked) {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: var(--white-color);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.isAllSelected :global(.ant-checkbox-wrapper-checked) {
    background-color: var(--black-10);
    border: 1px solid var(--black-40);
    color: var(--black-100);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}