.list {
    margin: 0 25px 0 25px;
    padding-bottom: 3em;
    border-radius: 8px;
    box-shadow: none;
}

.title {
    color: var(--black-100);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    margin: 0;
}

.list_top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 24px 16px 22px 16px;
}
.uploader_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ContainerWrapper {
    margin: 0 25px;
}

.ContainerWrapper :global(.t-card-container) {
    padding: 0;
}

.Table td span{
    color: var(--black-80);
}

.Table :global(.ant-table-pagination.ant-pagination) {
    margin: 24px 16px;
}

.Table :global(.ant-pagination-item-active) {
    background-color: var(--primary-color);
}

.Table :global(.ant-pagination-item-active a) {
    color: var(--white-color);
}

.success {
    color: var(--success-color) !important;
}

.warning {
    color: var(--warning-color) !important;;
}

.danger {
    color: var(--error-color) !important;;
}
