.container {
    padding-left: 32px;
    margin-bottom: 30px;
}
.button {
    font-weight: 500;
    letter-spacing: 0.1px;
    border-radius: 8px;
    background: var(--primary-color);
    border: none;
    height: 48px;
    box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16);
    margin-right: 12px;
}
.avatarWrapper {
    margin-right: 24px;
}
.changePasswordForm {
    padding: 0 8px 8px;
}
.changePasswordSaveButton {
    display: inline-block;
}
.container .uploadForm :global .ant-row.ant-form-item {
    margin-bottom: 0;
}
.container :global .t-card-container {
    margin-right: 32px;
}
.container :global .form {
    padding: 8px 8px 0;
    max-width: 800px;
}
.container :global .changePasswordForm {
    padding: 0 8px 8px;
    max-width: 800px;
}
.container :global .ant-form-item-label > label {
    color: var(--black-60);
}
.container
    :global
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.modalContainer {
    /* background-color: blueviolet; */
    border-radius: 8px;
}
.container :global .ant-form-item-extra {
    position: absolute;
    bottom: -25px;
}
