.arrowIcon {
    font-size: 9px;
}
.tableTitleWrapper {
    display: flex;
    align-items: center;
}
.sortingIconsWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
}
:global .ant-table-column-sorter-full {
    display: none;
}
