.vulnerability_container > :global(.t-card-container) {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.vulnerability_list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 15px 0;
    margin: 0 15px;
}
.label {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
}
.top_button {
    margin-right: 12px;
    border-radius: 4px;
}
.month_btn {
    margin-left: -15px;
}
.btn_group label {
    border: 1px solid var(--black-10);
}
.btn_group {
    display: flex;
    align-items: center;
}
.btn_group :global(.ant-radio-button-wrapper) {
    border-radius: 4px;
    background-color: var(--black-10);
}
.btn_group :global(.ant-radio-button-checked) {
    background-color: var(--primary-color);
    border-radius: 4px;
}
.btn_group :global(.ant-radio-button-wrapper:hover) {
    color: var(--white-color);
    background-color: var(--primary-color);
}
.vulnerability_container :global(.ant-table-row) {
    cursor: pointer;
}
