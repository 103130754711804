@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
:root {
    --primary-color: #006ad7;
    --primary-color-rgb: 0, 106, 215;
    --error-color: #fb4e4e;
    --warning-color: #f6a609;
    --success-color: #2ac769;
    --accent-color: #ffe500;
    --background-color: #f9f9fa;
    --text-color: #25282b;
    --white-color: #ffffff;
    --black-10: #e8e8e8;
    --black-20: #DBDDE0;
    --black-40: #cacccf;
    --black-60: #a0a4a8;
    --black-80: #52575c;
    --black-100: #25282b;
    --primary-10: #cad4df;
}

*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    background-color: #f9f9fa;
    background-color: var(--background-color);
    font-family: "Montserrat", sans-serif;
    height: 100vh;
}

/* Vulnerability status text color */
.default_color {
    color: #006ad7;
    color: var(--primary-color);
}
.not_vulnerable {
    color: #2ac769;
    color: var(--success-color);
}
.potentially_vulnerable {
    color: #f6a609;
    color: var(--warning-color);
}
.vulnerable {
    color: #fb4e4e;
    color: var(--error-color);
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
    border-color: #006ad7 !important;
    border-color: var(--primary-color) !important;
    box-shadow: none !important;
}

.ant-picker:hover {
    border-color: #006ad7;
    border-color: var(--primary-color);
}
.ant-table-pagination.ant-pagination {
    margin: 16px 16px;
}

.Sidebar_Sidebar__2ajNT {
    position: relative;
    flex-basis: 255px;
    width: 256px;
    flex-grow: 1;
    z-index: 2;
}

.Sidebar_SubMenuWrapper__knq-R,
.Sidebar_MenuWrapper__2mHP_ {
    position: fixed;
    height: 100%;
    width: inherit;
    background-color: var(--white-color);
    border-right: 1px solid var(--black-10);
    z-index: 2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Sidebar_isCollapsed__3mKGD {
    flex-basis: 52px;
    width: 53px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Sidebar_MenuItem__1aI-8 {
    cursor: pointer;
    position: relative;
    line-height: 48px;
    height: 48px;
    width: 100%;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Sidebar_active__37KeR,
.Sidebar_MenuItem__1aI-8:hover {
    background-color: rgba(var(--primary-color-rgb), 0.04);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Sidebar_MenuItem__1aI-8.Sidebar_active__37KeR:after,
.Sidebar_MenuItem__1aI-8:hover:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 32px;
    margin: 8px 0;
    width: 2px;
    border-radius: 0 5px 5px 0;
    background-color: var(--primary-color);
}

.Sidebar_MenuIcon__3P7BS svg {
    width: 100%;
    max-width: 16px;
    height: 100%;
    max-height: 16px;
    fill: var(--black-60);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Sidebar_MenuIcon__3P7BS {
    display: inline-block;
    position: relative;
    line-height: 16px;
    padding: 16px;
}

.Sidebar_MenuItem__1aI-8:hover .Sidebar_MenuLabel__EcAhZ {
    color: var(--primary-color);
}

.Sidebar_MenuItem__1aI-8:hover svg {
    fill: var(--primary-color);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Sidebar_MenuLabel__EcAhZ {
    position: absolute;
    top: 0;
    left: 52px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 204px;
}

.Sidebar_Brand__catqv {
    height: 90px;
}

.Sidebar_BrandIcon__37MY7 {
    line-height: 26px;
    padding: 32px 14px;
}

.Sidebar_Brand__catqv:hover {
    background-color: transparent;
}

.Sidebar_Brand__catqv.Sidebar_active__37KeR:after,
.Sidebar_Brand__catqv:hover:after {
    display: none;
}

.Sidebar_SubMenuHeader__1pwsS,
.Sidebar_Brand__catqv .Sidebar_MenuLabel__EcAhZ {
    font-size: 20px;
    line-height: 26px;
    padding: 32px 0;
    font-weight: 600;
    cursor: auto;
    cursor: initial;
}

.Sidebar_Brand__catqv .Sidebar_MenuLabel__EcAhZ a {
    color: var(--primary-color);
    cursor: pointer;
}

.Sidebar_isCollapsed__3mKGD .Sidebar_UserDetails__3FMtR,
.Sidebar_isCollapsed__3mKGD .Sidebar_MenuLabel__EcAhZ {
    left: -300px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Sidebar_Collapse__3Cu8d {
    position: absolute;
    top: 0;
    right: -8px;
    height: 100%;
    width: 1px;
    padding: 0 7px;
    cursor: w-resize;
}

.Sidebar_Collapse__3Cu8d div {
    height: 100%;
    background-color: var(--black-10);
}

.Sidebar_Collapse__3Cu8d:hover div {
    width: 2px;
    background-color: var(--primary-color);
}

.Sidebar_Collapse__3Cu8d:hover div:before {
    content: '';
    position: absolute;
    top: 30%;
    left: -1px;
    border-right: 10px solid var(--primary-color);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

.Sidebar_Collapse__3Cu8d:hover div:after,
.Sidebar_isCollapsed__3mKGD .Sidebar_Collapse__3Cu8d:hover div:before {
    border: none;
}

.Sidebar_isCollapsed__3mKGD .Sidebar_Collapse__3Cu8d:hover div:after {
    content: '';
    position: absolute;
    top: 30%;
    left: 7px;
    border-left: 10px solid var(--primary-color);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

.Sidebar_isCollapsed__3mKGD .Sidebar_Collapse__3Cu8d {
    cursor: e-resize;
}

.Sidebar_Avatar__375yj {
    padding: 24px 6px;
    position: absolute;
    border-top: 1px solid var(--black-10);
    bottom: 0;
    left: 0;
    width: inherit;
}

.Sidebar_Avatar__375yj img {
    vertical-align: middle;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.Sidebar_UserDetails__3FMtR {
    position: absolute;
    top: 24px;
    left: 52px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 204px;
    padding: 2px 12px;
}

.Sidebar_UserName__2Un3J {
    line-height: 16px;
    margin-bottom: 4px;
    color: var(--black-80);
    font-size: 12px;
}

.Sidebar_UserPosition__3AOqu {
    line-height: 16px;
    color: var(--black-100);
    font-weight: 500;
    font-size: 12px;
}

.Sidebar_UserDropdown__2dIF4 {
    position: absolute;
    top: 50%;
    right: 28px;
}

.Sidebar_UserMenu__2PPrs {
    position: absolute;
    bottom: 20px;
    left: 270px;
    list-style: none;
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    width: 256px;
    padding: 8px 0;
    margin: 0;
    background-color: var(--white-color);
    height: auto;
    display: none;
    animation: Sidebar_growDown__18Xvo 250ms ease-in-out forwards;
    transform-origin: top center;
    z-index: 99999;
}

.Sidebar_isCollapsed__3mKGD .Sidebar_UserMenu__2PPrs {
    left: 67px;
}

.Sidebar_UserMenu__2PPrs li:hover {
    background-color: rgba(var(--primary-color-rgb), 0.04);
}

.Sidebar_UserMenu__2PPrs li a {
    display: block;
    padding: 11px 16px;
    line-height: 18px;
    color: var(--black-80);
}

.Sidebar_UserMenu__2PPrs li a .anticon {
    padding-right: 10px;
}

.Sidebar_UserMenu__2PPrs li:hover a {
    color: var(--primary-color);
}

.Sidebar_show__1-oNG {
    display: block !important;
}

.Sidebar_hide__22iON {
    display: none !important;
}

.Sidebar_backdrop__3nhLF {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
}

.Sidebar_SubMenuWrapper__knq-R {
    z-index: 1;
    width: inherit;
    padding-left: 53px;
    transition: none;
}

.Sidebar_SubMenuHeader__1pwsS {
    color: var(--black-100);
    padding: 32px 16px;
}

.Sidebar_SubMenuWrapper__knq-R .Sidebar_MenuItem__1aI-8 a {
    padding-left: 16px;
    line-height: 48px;
    display: block;
    color: var(--black-100);
}

.Sidebar_SubMenuWrapper__knq-R .Sidebar_MenuItem__1aI-8:hover a {
    color: var(--primary-color);
}
.Sidebar_user_title__34k6f {
    display: block;
    position: absolute;
}
.Sidebar_arrow_down_icon__3GH1G {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex: 1 1;
    position: relative;
    top: 10px;
}

.modal_dialog {
    width: 394px !important;
    height: 202px !important;
}
.modal_dialog > .ant-modal-content {
    background: #FFFFFF;
    box-shadow: 0 12px 40px rgba(37, 40, 43, 0.32);
    border-radius: 8px !important;
}
.ant-modal-confirm-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.ant-modal-confirm-body  svg {
    position: relative;
    top: 3px;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
    display: block;
    padding-left: 14px;
    font-size: 20px;
    font-weight: normal;
    line-height: 26px;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
}
.ant-modal-confirm-btns button {
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    width: auto;
}
.ant-modal-confirm-btns button:nth-child(1) {
    color: var(--black-60);
}
.ant-modal-confirm-btns button:hover:nth-child(1) {
    color: var(--primary-color);
}
.ant-modal-confirm-btns button:nth-child(2) {
    background: var(--primary-color);
    background-color: var(--primary-color);
}
.ant-modal-confirm-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: var(--black-80);
}
.text_color {
    display: block;
    color: var(--primary-color);
}
.global_error_dialog .ant-modal-confirm-btns button {
    color: var(--white-color);
    background: var(--primary-color);
    background-color: var(--primary-color);
}
.global_error_dialog .ant-modal-confirm-btns span {
    color: var(--white-color);
}

/* Upload unsuccessful dialog */
.reason_list  {
    margin-top: -15px;
    margin-bottom: 15px;
    color: var(--black-80);
    list-style-type: none;
}
.reason_list > li:before {
    content:"- ";
}
.guide_label {
    color: var(--primary-color);
}
.alert .ant-modal-confirm-content {
    flex-basis: 100%;
}
.alert .ant-modal-confirm-body {
    display: flex;
    flex-wrap: wrap;
}
.alert .ant-btn-primary {
    background: var(--primary-color);
}
.alert .ant-modal-confirm-btns button:hover,
.alert .ant-modal-confirm-btns button {
    color: var(--white-color);
}

/* Delete file confirmation */
.delete_file .add_btn {
    width: 99px;
    height: 40px;
}
button.ant-btn-dangerous {
    width: 90px;
    height: 40px;
    color: var(--white-color) !important;
    background-color: var(--error-color) !important;
}
.delete_file button:nth-child(1) {
    margin-right: 0;
}

.Layout_Layout__2BpO9 {
    display: flex;
    flex-wrap: wrap;
}

.Layout_Content__3H3X8 {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 50%;
    min-height: 100vh;
}

.button {
    background: var(--primary-color);
    background-color: var(--primary-color);
    border-radius: 8px;
    height: 48px;
    width: 167px;
}
.button > span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
}
.button > svg {
    margin-left: 6px;
    fill: white;
    height: 20px;
    width: 20px;
}

.Header_header__1VCKf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 29px 32px;
}
.Header_divider__1t-WA {
    border-top-color: var(--black-60);
}
.Header_header_option__3PPYy {
    display: flex;
    align-items: center;
    justify-content: center;
}
.Header_header_option__3PPYy > div:nth-child(1) {
    margin-bottom: 0;
}

.Title_container__2EHed {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.Title_container__2EHed p {
    color: var(--text-color);
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.2px;
}
.SearchInput_search_input__3KTbh {
    height: 48px;
    width: 256px;
    outline: none;
    border-radius: 8px;
    background-color: transparent;
    border-color: var(--black-60);
    margin-right: 24px;
}
.SearchInput_search_input__3KTbh input {
    background-color: transparent;
}
.SearchInput_search_container__3V4GB {
    display: flex;
    top: 30px;
    right: 12em;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Popover_popup_text__1ZOhb {
    display: flex;
    justify-content: center;
    flex: 1 1;
    width: 260px;
}
.Popover_popup_text__1ZOhb p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: var(--black-80);
    text-align: start;
}
.Popover_popup_text__1ZOhb svg {
    width: 60px;
    padding-right: 13px;
}
.StatisticList_statistic_list__Z82BV {
    margin: 25px;
    height: 202px;
}
.StatisticList_statistic_header__36rZ4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.StatisticList_header_first__3DmzB {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.StatisticList_header_first__3DmzB p:nth-child(1) {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    padding-right: 10px;
}
.StatisticList_header_first__3DmzB p:nth-child(2) {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
}
.StatisticList_header_second__1H7xA p:nth-child(1) {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: var(--primary-color);
}
.StatisticList_statistic_base_container__ntDHc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: -25px 0;
}
.StatisticList_statistic_container__3mpM4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    flex-direction: row;
    width: 100%;
}
.StatisticList_circle_diagram__1aL52 {
    margin: 25px 20px;
}
.StatisticList_circle_diagram_text__3nErT p:nth-child(1) {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: var(--black-100);
}
.StatisticList_circle_diagram_text__3nErT p svg {
    margin-left: 10px;
}
.StatisticList_customer_count__13Amm {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: var(--black-60);
    letter-spacing: 0.1px;
}
.StatisticList_horizontal_divider__3d49E {
    border: 1px solid var(--black-10);
    margin: 22px 0;
    width: 100%;
}
.StatisticList_vertical_divider__wz_1y {
    height: 9em;
    margin-bottom: 10px;
    border: 1px solid var(--black-10);
}

.Statistic_list__sicCu {
    margin: 0 25px 0 25px;
    padding-bottom: 3em;
    border-radius: 8px;
    box-shadow: none;
}
.Statistic_list__sicCu > .t-card-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.Statistic_title__tsR9l {
    color: var(--black-100);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
}
.Statistic_list_top__3OiaF {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 24px 0 24px;
}
.Statistic_button_list__2KE8m {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-end;
    margin-right: 40px;
    align-self: center;
}
.Statistic_list__sicCu .ant-table-row {
    cursor: pointer;
}
.NoDataAvailable_empty_space__1TlmJ {
    display: flex;
    justify-content: center;
    align-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 400px;
}
.NoDataAvailable_empty_space__1TlmJ svg {
    margin-bottom: 15px;
    width: auto;
    height: 3rem;
}
.NoDataAvailable_empty_space__1TlmJ span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--black-100);
}

.ApexChart_graph_list__1yANn {
    min-height: 465px;
    margin: 0 1em 0 1em;
}
.ApexChart_content_loader__wilEZ {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 1;
    flex-wrap: nowrap;
    min-height: 400px;
}
.ApexChart_y_axis__3kQrU {
    display: inline-block;
    position: relative;
    transform: translate(-43%, 14em);
    margin: 0;
    z-index: 1;
}
.ApexChart_y_axis_text__1UcDU {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--black-100);
    transform: rotate(-90deg);
    margin-left: 0;
}

/* Legend customisation */
.ApexChart_legend_container__-f-iQ {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 4em 0;
}
.ApexChart_legend__1BsQ0 {
    color: var(--black-80);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    display: inline-block;
    margin: 0 -16%;
}
.ApexChart_not_vulnerable__2x58x {
    margin-bottom: 12px;
    width: 200px;
    border: 1px solid var(--success-color);
}
.ApexChart_potentially_vulnerable__34FbB  {
    margin-bottom: 12px;
    width: 200px;
    border: 1px solid var(--warning-color);
}
.ApexChart_vulnerable__2aoAB {
    margin-bottom: 12px;
    width: 200px;
    border: 1px solid var(--error-color);
}
/* Hide xaxis all values */
.apexcharts-xaxis-texts-g text[id^='SvgjsText'] {
    display: none;
}
/* Revert xaxis first and last values */
.apexcharts-xaxis-texts-g text[id^='SvgjsText']:nth-of-type(1),
.apexcharts-xaxis-texts-g text[id^='SvgjsText']:nth-last-of-type(1) {
    display: revert;
}

.styles_arrowIcon__1lSW5 {
    font-size: 9px;
}
.styles_tableTitleWrapper__1G5zy {
    display: flex;
    align-items: center;
}
.styles_sortingIconsWrapper__1KMz4 {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
}
.ant-table-column-sorter-full {
    display: none;
}

.ButtonList_button_list__hUjIM {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--black-100);
    border-radius: 4px;
    background: var(--black-10);
    border: 1px solid var(--black-40);
}
.ButtonList_button__1Qurt {
    border-radius: 8px !important;
}
button:nth-child(1) {
    margin-right: 12px;
}
.ButtonList_active__258Kl,
.ButtonList_button_list__hUjIM:hover,
.ButtonList_button_list__hUjIM:active,
.ButtonList_button_list__hUjIM:focus {
    color: var(--white-color);
    border-color: var(--primary-color);
    background: var(--primary-color);
}
.ButtonList_hide__hvzC5 {
    display: none;
}
.ButtonList_show___XTtR {
    display: inline-block;
}

.ToggleButton_diagram_button__2iHDl,
.ToggleButton_order_button__3BHqK {
    width: 68px;
    height: 34px;
    border-radius: 1px solid var(--black-10) !important;
    background-color: var(--background-color) !important;
    text-align: center;
    vertical-align: middle;
}
.ToggleButton_diagram_button__2iHDl {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}
.ToggleButton_order_button__3BHqK {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.ToggleButton_diagram_button__2iHDl:hover,
.ToggleButton_order_button__3BHqK:hover {
    background: var(--white-color) !important;
    fill: var(--primary-color) !important;
}
label[class~='ant-radio-button-wrapper-checked'] {
    border-color: var(--black-10) !important;
}
label.ToggleButton_diagram_button__2iHDl > span[class~="ant-radio-button-checked"] {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    background: var(--white-color) !important;
}
label.ToggleButton_order_button__3BHqK > span[class~="ant-radio-button-checked"] {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    background: var(--white-color) !important;
}
label.ToggleButton_diagram_button__2iHDl > span[class~="ant-radio-button-checked"] + span svg,
label.ToggleButton_order_button__3BHqK > span[class~="ant-radio-button-checked"] + span svg {
    fill: var(--primary-color) !important;
}

.ButtonGroup_button_group__UuyFm {
    margin: -20px 0 0 13px;
}

.ButtonGroup_Checkbox__3d7u-,
.ButtonGroup_button_group__UuyFm .ant-checkbox-group-item {
    line-height: 18px;
    color: var(--black-100);
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    background-color: var(--black-10);
    border: 1px solid var(--black-40);
    border-radius: 4px;
    margin-right: 24px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ButtonGroup_button_group__UuyFm .ant-checkbox {
    display: none;
}

.ButtonGroup_active__3e-0Z,
.ButtonGroup_active__3e-0Z:hover,
.ButtonGroup_button_group__UuyFm .ant-checkbox-group-item:hover,
.ButtonGroup_button_group__UuyFm .ant-checkbox-wrapper-checked {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: var(--white-color);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ButtonGroup_isAllSelected__3D6Ks .ant-checkbox-wrapper-checked {
    background-color: var(--black-10);
    border: 1px solid var(--black-40);
    color: var(--black-100);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.SpecificRange_DatePicker__hqCME {
    padding: 0;
    margin: 12px 16px;
    width: 224px;
}

.SpecificRange_DatePicker__hqCME input {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 10px;
    width: 108px;
    color: var(--primary-color);
}

.SpecificRange_DatePicker__hqCME input:first-child {
    margin-right: 8px;
}

.SpecificRange_DatePicker__hqCME input:hover {
    border: 1px solid var(--primary-color);
}

.SpecificRange_DatePicker__hqCME .ant-picker-active-bar,
.SpecificRange_DatePicker__hqCME .ant-picker-range-separator {
    display: none;
}

.SpecificRange_DatePicker__hqCME.ant-picker-focused {
    box-shadow: none;
}

.SpecificRange_DatePicker__hqCME.ant-picker {
    background-color: transparent;
    border: none;
}

.SpecificRange_Dropdown__22nAM {
    z-index: 99999;
}

.SpecificRange_Dropdown__22nAM .ant-picker-range-arrow {
    display: none;
}

.SpecificRange_Dropdown__22nAM .ant-picker-date-panel {
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    padding: 16px;
    margin: 0;
    background-color: var(--white-color);
    position: absolute;
    left: 240px;
    top: -48px;
    width: 368px;
    height: auto;
}

.SpecificRange_Dropdown__22nAM .ant-picker-panels .ant-picker-panel:last-child {
    display: none;
}

.SpecificRange_Dropdown__22nAM .ant-picker-panels .ant-picker-panel:first-child .ant-picker-header-next-btn,
.SpecificRange_Dropdown__22nAM .ant-picker-panels .ant-picker-panel:first-child .ant-picker-header-super-next-btn {
    visibility: visible !important;
}

.SpecificRange_Dropdown__22nAM .ant-picker-header {
    border-bottom: none;
}

.SpecificRange_Dropdown__22nAM .ant-picker-content {
    width: 100%;
}

.SpecificRange_Dropdown__22nAM .ant-picker-content th {
    font-size: 12px;
    line-height: 16px;
    color: var(--black-60);
}

.SpecificRange_Dropdown__22nAM .ant-picker-date-panel .ant-picker-body {
    padding: 0;
}

.SpecificRange_Dropdown__22nAM .ant-picker-cell {
    height: 48px;
    width: 48px;
}

.SpecificRange_Dropdown__22nAM .ant-picker-cell-range-end,
.SpecificRange_Dropdown__22nAM .ant-picker-cell-range-start,
.SpecificRange_Dropdown__22nAM .ant-picker-cell-selected {
    background-color: var(--primary-color);
    border-radius: 8px;
}

.SpecificRange_Dropdown__22nAM .ant-picker-cell-range-end .ant-picker-cell-inner,
.SpecificRange_Dropdown__22nAM .ant-picker-cell-range-start .ant-picker-cell-inner,
.SpecificRange_Dropdown__22nAM .ant-picker-cell-selected .ant-picker-cell-inner {
    background: var(--primary-color);
}

.SpecificRange_Dropdown__22nAM .ant-picker-cell .ant-picker-cell-inner {
    font-size: 14px;
    line-height: 18px;
}

.SpecificRange_Dropdown__22nAM .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    top: -13px;
    left: -13px;
    height: 48px;
    width: 48px;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
}

.SpecificRange_Dropdown__22nAM .ant-picker-cell-in-range:before,
.SpecificRange_Dropdown__22nAM .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-start-single)::before,
.SpecificRange_Dropdown__22nAM .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
    display: none;
}

.SpecificRange_Dropdown__22nAM .ant-picker-cell-in-range {
    background: var(--black-10);
}
.ByDay_DatePicker__1GRi- {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    margin: 12px 16px;
    width: 224px;
}

.ByDay_DatePicker__1GRi- input {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.ByDay_Dropdown__1Dm4m {
    z-index: 99999;
}

.ByDay_Dropdown__1Dm4m .ant-picker-date-panel {
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    padding: 16px;
    margin: 0;
    background-color: var(--white-color);
    position: absolute;
    left: 240px;
    top: -48px;
    width: 368px;
    height: auto;
}

.ByDay_Dropdown__1Dm4m .ant-picker-header {
    border-bottom: none;
}

.ByDay_Dropdown__1Dm4m .ant-picker-content {
    width: 100%;
}

.ByDay_Dropdown__1Dm4m .ant-picker-content th {
    font-size: 12px;
    line-height: 16px;
    color: var(--black-60);
}

.ByDay_Dropdown__1Dm4m .ant-picker-date-panel .ant-picker-body {
    padding: 0;
}

.ByDay_Dropdown__1Dm4m .ant-picker-cell {
    height: 48px;
    width: 48px;
}

.ByDay_Dropdown__1Dm4m .ant-picker-cell-selected {
    background-color: var(--primary-color);
    border-radius: 8px;
}

.ByDay_Dropdown__1Dm4m .ant-picker-cell-selected .ant-picker-cell-inner {
    background: var(--primary-color);
}

.ByDay_Dropdown__1Dm4m .ant-picker-cell .ant-picker-cell-inner {
    font-size: 14px;
    line-height: 18px;
}

.ByDay_Dropdown__1Dm4m .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    top: -13px;
    left: -13px;
    height: 48px;
    width: 48px;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
}
.ByWeek_DatePicker__Q2ud8 {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    margin: 12px 16px;
    width: 224px;
}

.ByWeek_DatePicker__Q2ud8 input {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.ByWeek_Dropdown__3h2U0 {
    z-index: 99999;
}

.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel {
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    padding: 16px;
    margin: 0;
    background-color: var(--white-color);
    position: absolute;
    left: 240px;
    top: -48px;
    width: 416px;
    height: auto;
}

.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel-row-selected td {
    background: var(--black-10);
}

.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel-row-selected .ant-picker-cell:last-child,
.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel-row-selected .ant-picker-cell-selected {
    background: var(--primary-color);
    border-radius: 8px;
}

.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel-row-selected .ant-picker-cell-week {
    background: var(--white-color);
    color: rgba(0, 0, 0, 0.25);
}

.ByWeek_Dropdown__3h2U0 .ant-picker-header {
    border-bottom: none;
}

.ByWeek_Dropdown__3h2U0 .ant-picker-content {
    width: 100%;
}

.ByWeek_Dropdown__3h2U0 .ant-picker-content th {
    font-size: 12px;
    line-height: 16px;
    color: var(--black-60);
}

.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel .ant-picker-body {
    padding: 0;
}

.ByWeek_Dropdown__3h2U0 .ant-picker-cell {
    height: 48px;
    width: 48px;
}

.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel-row-selected .ant-picker-cell-inner {
    color: var(--primary-color);
}

.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel-row-selected .ant-picker-cell:last-child .ant-picker-cell-inner,
.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel-row-selected .ant-picker-cell-selected .ant-picker-cell-inner {
    color: var(--white-color);
}

.ByWeek_Dropdown__3h2U0 .ant-picker-week-panel-row-selected:hover .ant-picker-cell-week {
    color: rgba(0, 0, 0, 0.25);
}

.ByWeek_Dropdown__3h2U0 .ant-picker-cell .ant-picker-cell-inner {
    font-size: 14px;
    line-height: 18px;
}

.ByWeek_Dropdown__3h2U0 .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    top: -13px;
    left: -13px;
    height: 48px;
    width: 48px;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
}
.ByMonth_InputNumberWrapper__1zpDc {
    margin: 12px 16px;
}

.ByMonth_Select__1y4uw {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    width: 108px;
}

.ByMonth_Select__1y4uw:first-child {
    margin-right: 8px;
}

.ByMonth_Select__1y4uw .ant-select-selector {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-color);
}


.ByMonth_Select__1y4uw .ant-select-open .ant-select-selector,
.ByMonth_Select__1y4uw .ant-select-selector:active {
    border: none !important;
}

.ByMonth_Select__1y4uw.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 5px 16px;
    height: auto;
    border: none;
}

.ByMonth_Dropdown__2nv8q {
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    padding: 8px 0;
    z-index: 99999;
}

.ByMonth_Dropdown__2nv8q .ant-select-item-option {
    padding: 11px 16px;
}

.ByMonth_Dropdown__2nv8q .ant-select-item-option-content {
    line-height: 18px;
}

.ByYear_Select__2ULnm {
    background: var(--white-color);
    /*background: red;*/
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 12px 16px;
    width: 224px;
}

.ByYear_Select__2ULnm .ant-select-selector {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-color);
}


.ByYear_Select__2ULnm .ant-select-open .ant-select-selector,
.ByYear_Select__2ULnm .ant-select-selector:active {
    border: none !important;
}

.ByYear_Select__2ULnm.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 5px 16px;
    height: auto;
    border: none;
}

.ByYear_Dropdown__3L4hf {
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    padding: 8px 0;
    z-index: 99999;
}

.ByYear_Dropdown__3L4hf .ant-select-item-option {
    padding: 11px 16px;
}

.ByYear_Dropdown__3L4hf .ant-select-item-option-content {
    line-height: 18px;
}

.Above_InputNumber__3EPQR {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    margin: 12px 16px;
    width: 224px;
}

.Above_InputNumber__3EPQR .ant-input-number-input {
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.Above_InputNumber__3EPQR .ant-input-number-handler-wrap {
    margin-right: 8px;
    border: none;
}

.Above_InputNumber__3EPQR .ant-input-number-handler-down {
    border: none;
}
.Below_InputNumber__2Ho5t {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    margin: 12px 16px;
    width: 224px;
}

.Below_InputNumber__2Ho5t .ant-input-number-input {
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.Below_InputNumber__2Ho5t .ant-input-number-handler-wrap {
    margin-right: 8px;
    border: none;
}

.Below_InputNumber__2Ho5t .ant-input-number-handler-down {
    border: none;
}
.SpecificRange_InputNumberWrapper__1EjKJ {
    margin: 12px 16px;
}

.SpecificRange_InputNumber__2gj1Z {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    width: 108px;
}

.SpecificRange_InputNumber__2gj1Z:first-child {
    margin-right: 8px;
}

.SpecificRange_InputNumber__2gj1Z .ant-input-number-input {
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.SpecificRange_InputNumber__2gj1Z .ant-input-number-handler-wrap {
    margin-right: 8px;
    border: none;
}

.SpecificRange_InputNumber__2gj1Z .ant-input-number-handler-down {
    border: none;
}
.Above_InputNumber__1vx5F {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    margin: 12px 16px;
    width: 224px;
}

.Above_InputNumber__1vx5F .ant-input-number-input {
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.Above_InputNumber__1vx5F .ant-input-number-handler-wrap {
    margin-right: 8px;
    border: none;
}

.Above_InputNumber__1vx5F .ant-input-number-handler-down {
    border: none;
}
.Below_InputNumber__2DPYB {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    margin: 12px 16px;
    width: 224px;
}

.Below_InputNumber__2DPYB .ant-input-number-input {
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.Below_InputNumber__2DPYB .ant-input-number-handler-wrap {
    margin-right: 8px;
    border: none;
}

.Below_InputNumber__2DPYB .ant-input-number-handler-down {
    border: none;
}
.SpecificRange_InputNumberWrapper__1neOE {
    margin: 12px 16px;
}

.SpecificRange_InputNumber__nf3zq {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    width: 108px;
}

.SpecificRange_InputNumber__nf3zq:first-child {
    margin-right: 8px;
}

.SpecificRange_InputNumber__nf3zq .ant-input-number-input {
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.SpecificRange_InputNumber__nf3zq .ant-input-number-handler-wrap {
    margin-right: 8px;
    border: none;
}

.SpecificRange_InputNumber__nf3zq .ant-input-number-handler-down {
    border: none;
}
.FinancialAbuseRiskFilter_DropdownMenu__6vSSA {
    display: inline-block;
    padding: 13px 24px;
    position: relative;
}

.FinancialAbuseRiskFilter_DropdownLabel__3AQzb {
    display: inline-block;
    color: var(--primary-color) !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    margin-left: 25px;
    cursor: pointer;
    position: relative;
    z-index: 99999;
}

.FinancialAbuseRiskFilter_DropdownLabel__3AQzb svg {
    width: 18px;
    height: 18px;
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA ul {
    position: absolute;
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    list-style: none;
    border-radius: 8px;
    width: 256px;
    padding: 8px 0;
    margin: 0;
    background-color: var(--white-color);
    height: auto;
    display: none;
    animation: FinancialAbuseRiskFilter_growDown__1xbmL 250ms ease-in-out forwards;
    transform-origin: top center;
    z-index: 99999;
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA > ul {
    top: 48px;
    left: 25px;
    width: 256px;
}

.FinancialAbuseRiskFilter_show__39Zvd {
    display: block !important;
}

.FinancialAbuseRiskFilter_hide__1e4Hq {
    display: none !important;
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA li {
    position: relative;
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA .ant-radio-group {
    display: block;
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA li .ant-radio-wrapper,
.FinancialAbuseRiskFilter_DropdownMenu__6vSSA li a {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: var(--black-80);
    margin: 0;
    padding: 11px 16px;
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA .ant-radio-wrapper:hover,
.FinancialAbuseRiskFilter_DropdownMenu__6vSSA li a:hover {
    color: var(--primary-color);
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA li:hover {
    background-color: rgba(var(--primary-color-rgb), 0.04);
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA > ul ul {
    left: 256px;
    top: -8px;
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA .ant-radio-inner {
    height: 20px;
    width: 20px;
    border: 2px solid var(--black-60);
    background-color: transparent;
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA .ant-radio-inner::after {
    height: 10px;
    width: 10px;
    border: 0;
    background-color: var(--primary-color);
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA .ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color);
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA .ant-radio:hover .ant-radio-inner {
    border-color: var(--black-60);
}

.FinancialAbuseRiskFilter_DropdownMenu__6vSSA .ant-radio-checked:hover .ant-radio-inner {
    border-color: var(--primary-color);
}

.FinancialAbuseRiskFilter_DropdownTag__XB814 {
    background-color: var(--black-10);
    border: 1px solid var(--black-40);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 16px;
}

.FinancialAbuseRiskFilter_DropdownTag__XB814.numberOfAccountsTag {
    margin-left: 12px;
}

.FinancialAbuseRiskFilter_DropdownTag__XB814.ant-tag {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.FinancialAbuseRiskFilter_backdrop__3H-5h {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
}

/* Animations */
@keyframes FinancialAbuseRiskFilter_growDown__1xbmL {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}

.PageTitle_container__2Ru5a {
    /* border-bottom: 1px solid #a0a4a8; */
    padding-top: 20px;
}
.PageTitle_title__1OOw7 {
    font-size: 26px;
    margin-bottom: 20px;
}
.PageTitle_divider__2_l5M {
    border-top: 1px solid #a0a4a8;
}

.Input_input__HkNJD {
    border-radius: 8px;
    border: 1px solid #a0a4a8;
    padding: 12px 16px;
}

.Profile_container__WdMDr {
    padding-left: 32px;
    margin-bottom: 30px;
}
.Profile_button__1Rl1o {
    font-weight: 500;
    letter-spacing: 0.1px;
    border-radius: 8px;
    background: var(--primary-color);
    border: none;
    height: 48px;
    box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16);
    margin-right: 12px;
}
.Profile_avatarWrapper__2s4XI {
    margin-right: 24px;
}
.Profile_changePasswordForm__3ZAkS {
    padding: 0 8px 8px;
}
.Profile_changePasswordSaveButton__o6ny5 {
    display: inline-block;
}
.Profile_container__WdMDr .Profile_uploadForm__29wa0 .ant-row.ant-form-item {
    margin-bottom: 0;
}
.Profile_container__WdMDr .t-card-container {
    margin-right: 32px;
}
.Profile_container__WdMDr .form {
    padding: 8px 8px 0;
    max-width: 800px;
}
.Profile_container__WdMDr .changePasswordForm {
    padding: 0 8px 8px;
    max-width: 800px;
}
.Profile_container__WdMDr .ant-form-item-label > label {
    color: var(--black-60);
}
.Profile_container__WdMDr
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.Profile_modalContainer__pSWWU {
    /* background-color: blueviolet; */
    border-radius: 8px;
}
.Profile_container__WdMDr .ant-form-item-extra {
    position: absolute;
    bottom: -25px;
}

.Registration_container__10_H4 {
    max-width: 540px;
    margin: 0 auto;
    border-radius: 8px;
    margin-top: 160px;
    margin-bottom: 50px;
}
.Registration_header__1Dn_v {
    padding: 24px 0;
    background-color: var(--primary-color);
    border-radius: 8px 8px 0 0;
    text-align: center;
}
.Registration_buttonWrapper__D3Jgb {
    margin-bottom: 24px;
}
.Registration_button__erC1s {
    height: 48px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--primary-color);
}
.Registration_forgotPasswordWrapper__2zpE8 {
    padding: 0 95px;
}
.Registration_forgotPasswordContainer__3gPLp {
    text-align: center;
}
.Registration_forgotPassword__2Ygnd {
    color: var(--primary-color);
    text-align: center;
    cursor: pointer;
}
.Registration_container__10_H4 .ant-tabs-nav-list {
    width: 100%;
}
.Registration_container__10_H4 .ant-tabs-content-holder {
    padding: 0 95px;
}
.Registration_container__10_H4 .ant-tabs-nav-list .ant-tabs-ink-bar {
    background-color: var(--primary-color);
}
.Registration_container__10_H4 .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color);
}
.Registration_container__10_H4 .ant-tabs-tab {
    width: 100%;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
}
.Registration_container__10_H4 .ant-tabs-nav-operations,
.Registration_container__10_H4 .ant-tabs-nav-operations-hidden {
    width: 0;
    display: none;
}
.Registration_container__10_H4 .t-tabs-container .t-tabs .ant-tabs-nav {
    padding-left: 0;
    padding-right: 0;
}
.Registration_container__10_H4 .ant-form-item-label > label {
    color: var(--black-60);
}
.Registration_container__10_H4
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.Registration_verifyEmailWrapper__386EO {
    text-align: center;
    padding: 32px 95px 24px;
}
.Registration_text__3DvOs {
    color: var(--black-80);
    text-align: left;
    margin: 24px 0;
}
.Registration_container__10_H4 .ant-form-item-extra {
    position: absolute;
    bottom: -25px;
}

.Customers_customers_dashboard__3NRl_ {
    margin: 25px;
}
.Customers_button__2YqRY {
    margin: 0 32px;
    border-radius: 4px;
    background-color: var(--primary-color);
    height: 34px;
    width: auto;
}
.Customers_button__2YqRY span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--white-color);
}

.Breadcrumbs_breadcrumb_container__1BETk {
    margin-bottom: 12px;
    margin-top: 12px;
    position: relative;
    top: 0;
    margin-left: 15px;
}
.Breadcrumbs_breadcrumb_item__1E4s8 a {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--black-60);
}
.Breadcrumbs_breadcrumb_item__1E4s8 a:hover {
    color: var(--primary-color);
}
.Breadcrumbs_breadcrumb_link__3stWn {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--black-60) !important;
    margin-right: -12px !important;
}
.Breadcrumbs_breadcrumb_container__1BETk > span:last-of-type .Breadcrumbs_breadcrumb_link__3stWn{
    color: var(--primary-color) !important;
}
.MonthList_event_btn__IVU3A {
    margin-left: -15px;
    margin-top: -5px;
    margin-bottom: -4px;
}
.MonthList_horizontal_divider__1aihW {
    border: 1px solid var(--black-10);
    margin: 0;
    width: 100%;
}
.MonthList_month_table__1xKJE .ant-table-tbody tr.ant-table-row:hover td {
    background-color: var(--primary-10);
}
.MonthList_activeRow__a47j8 {
    background-color: var(--primary-10);
}
.MonthList_month_table__1xKJE .ant-table-row {
    cursor: pointer;
}
.Vulnerability_vulnerability_container__1mH4z > .t-card-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.Vulnerability_vulnerability_list__3gnMF {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 15px 0;
    margin: 0 15px;
}
.Vulnerability_label__3tJtj {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
}
.Vulnerability_top_button__1_MBs {
    margin-right: 12px;
    border-radius: 4px;
}
.Vulnerability_month_btn__3ZWvn {
    margin-left: -15px;
}
.Vulnerability_btn_group__2xuEj label {
    border: 1px solid var(--black-10);
}
.Vulnerability_btn_group__2xuEj {
    display: flex;
    align-items: center;
}
.Vulnerability_btn_group__2xuEj .ant-radio-button-wrapper {
    border-radius: 4px;
    background-color: var(--black-10);
}
.Vulnerability_btn_group__2xuEj .ant-radio-button-checked {
    background-color: var(--primary-color);
    border-radius: 4px;
}
.Vulnerability_btn_group__2xuEj .ant-radio-button-wrapper:hover {
    color: var(--white-color);
    background-color: var(--primary-color);
}
.Vulnerability_vulnerability_container__1mH4z .ant-table-row {
    cursor: pointer;
}

.Transaction_horizontal_divider__2i6xH {
    border: 1px solid var(--black-10);
    margin: 25px 0 0 0;
}


.Breakdown_breakdown_content__2ZZgX {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin: 35px;
    text-align: center;
}
.Breakdown_breakdown_content__2ZZgX > svg {
    width: 10em;
    height: 5em;
    padding: 0 15px 10px 0;
    margin-bottom: 10px;
    overflow: inherit !important;
}
.Breakdown_breakdown_container__1ZzSm h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: var(--black-100);
    letter-spacing: 0.2px;
    margin: 15px;
}
.Breakdown_breakdown_container__1ZzSm h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    padding-top: 33px;
}
.Breakdown_breakdown_container__1ZzSm > .t-card-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.DataUploadHistory_list__21E7u {
    margin: 0 25px 0 25px;
    padding-bottom: 3em;
    border-radius: 8px;
    box-shadow: none;
}

.DataUploadHistory_title__3LJnP {
    color: var(--black-100);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    margin: 0;
}

.DataUploadHistory_list_top__2PR3l {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 24px 16px 22px 16px;
}
.DataUploadHistory_uploader_container__3zZKX {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DataUploadHistory_ContainerWrapper__1fwjk {
    margin: 0 25px;
}

.DataUploadHistory_ContainerWrapper__1fwjk .t-card-container {
    padding: 0;
}

.DataUploadHistory_Table__3P6H4 td span{
    color: var(--black-80);
}

.DataUploadHistory_Table__3P6H4 .ant-table-pagination.ant-pagination {
    margin: 24px 16px;
}

.DataUploadHistory_Table__3P6H4 .ant-pagination-item-active {
    background-color: var(--primary-color);
}

.DataUploadHistory_Table__3P6H4 .ant-pagination-item-active a {
    color: var(--white-color);
}

.DataUploadHistory_success__2o889 {
    color: var(--success-color) !important;
}

.DataUploadHistory_warning__12o8z {
    color: var(--warning-color) !important;;
}

.DataUploadHistory_danger__3G0kq {
    color: var(--error-color) !important;;
}


.BatchUploadHistory_title__3Bx9c {
    color: var(--black-100);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    margin: 0;
}

.BatchUploadHistory_list_top__CVcOi {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 24px 16px 22px 16px;
}

.BatchUploadHistory_ContainerWrapper__yCpQm {
    margin: 0 25px;
}

.BatchUploadHistory_ContainerWrapper__yCpQm .t-card-container {
    padding: 0;
}

.BatchUploadHistory_Table__3zgO0 td span{
    color: var(--black-80);
}

.BatchUploadHistory_Table__3zgO0 .ant-table-pagination.ant-pagination {
    margin: 24px 16px;
}

.BatchUploadHistory_Table__3zgO0 .ant-pagination-item-active {
    background-color: var(--primary-color);
}

.BatchUploadHistory_Table__3zgO0 .ant-pagination-item-active a {
    color: var(--white-color);
}

.BatchUploadHistory_success__2UzkO {
    color: var(--success-color) !important;
}

.BatchUploadHistory_warning__1j1Nw {
    color: var(--warning-color) !important;;
}

.BatchUploadHistory_danger__BTj1A {
    color: var(--error-color) !important;;
}

.PageNotFound_section__2KuMe {
    width: 1110px;
    height: 562px;
    display: grid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.PageNotFound_container__24v4I {
    height: 562px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: space-around;
    justify-content: space-around;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(34, 60, 80, 0.2);
}
.PageNotFound_text_content__Zomi3 {
    width: 350px;
}
.PageNotFound_title_first__2wmcM,
.PageNotFound_title_second__3BGeE,
.PageNotFound_error_code__145WI {
    letter-spacing: 0.1px;
    color: var(--primary-color);
    font-style: normal;
}
.PageNotFound_title_first__2wmcM {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
}
.PageNotFound_title_second__3BGeE,
.PageNotFound_error_code__145WI {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}
.PageNotFound_authenticated__EtA1Q {
    display: grid;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1110px;
    height: 562px;
    margin: auto;
    transform: translate(-40%,-50%);
}
.PageNotFound_top_logo__y9gLj {
    display: flex;
    position: relative;
    top: -5em;
    flex-wrap: wrap;
    justify-content: start;
    align-content: space-between;
}
.PageNotFound_top_logo__y9gLj img {
    height: 35px;
    margin-top: -5px;
}
.PageNotFound_logo_text__MJSxq {
    padding-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 26px;
    color: var(--primary-color);
}
.PageNotFound_go_home_button__c7kSV {
    background: var(--primary-color);
    border-radius: 4px;
    height: 40px;
    width: 350px;
    left: 0;
    top: 0;
}
.PageNotFound_go_home_button__c7kSV a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--white-color);
}

