.Sidebar {
    position: relative;
    flex-basis: 255px;
    width: 256px;
    flex-grow: 1;
    z-index: 2;
}

.SubMenuWrapper,
.MenuWrapper {
    position: fixed;
    height: 100%;
    width: inherit;
    background-color: var(--white-color);
    border-right: 1px solid var(--black-10);
    z-index: 2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.isCollapsed {
    flex-basis: 52px;
    width: 53px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.MenuItem {
    cursor: pointer;
    position: relative;
    line-height: 48px;
    height: 48px;
    width: 100%;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.active,
.MenuItem:hover {
    background-color: rgba(var(--primary-color-rgb), 0.04);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.MenuItem.active:after,
.MenuItem:hover:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 32px;
    margin: 8px 0;
    width: 2px;
    border-radius: 0 5px 5px 0;
    background-color: var(--primary-color);
}

.MenuIcon svg {
    width: 100%;
    max-width: 16px;
    height: 100%;
    max-height: 16px;
    fill: var(--black-60);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.MenuIcon {
    display: inline-block;
    position: relative;
    line-height: 16px;
    padding: 16px;
}

.MenuItem:hover .MenuLabel {
    color: var(--primary-color);
}

.MenuItem:hover svg {
    fill: var(--primary-color);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.MenuLabel {
    position: absolute;
    top: 0;
    left: 52px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 204px;
}

.Brand {
    height: 90px;
}

.BrandIcon {
    line-height: 26px;
    padding: 32px 14px;
}

.Brand:hover {
    background-color: transparent;
}

.Brand.active:after,
.Brand:hover:after {
    display: none;
}

.SubMenuHeader,
.Brand .MenuLabel {
    font-size: 20px;
    line-height: 26px;
    padding: 32px 0;
    font-weight: 600;
    cursor: initial;
}

.Brand .MenuLabel a {
    color: var(--primary-color);
    cursor: pointer;
}

.isCollapsed .UserDetails,
.isCollapsed .MenuLabel {
    left: -300px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Collapse {
    position: absolute;
    top: 0;
    right: -8px;
    height: 100%;
    width: 1px;
    padding: 0 7px;
    cursor: w-resize;
}

.Collapse div {
    height: 100%;
    background-color: var(--black-10);
}

.Collapse:hover div {
    width: 2px;
    background-color: var(--primary-color);
}

.Collapse:hover div:before {
    content: '';
    position: absolute;
    top: 30%;
    left: -1px;
    border-right: 10px solid var(--primary-color);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

.Collapse:hover div:after,
.isCollapsed .Collapse:hover div:before {
    border: none;
}

.isCollapsed .Collapse:hover div:after {
    content: '';
    position: absolute;
    top: 30%;
    left: 7px;
    border-left: 10px solid var(--primary-color);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

.isCollapsed .Collapse {
    cursor: e-resize;
}

.Avatar {
    padding: 24px 6px;
    position: absolute;
    border-top: 1px solid var(--black-10);
    bottom: 0;
    left: 0;
    width: inherit;
}

.Avatar img {
    vertical-align: middle;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.UserDetails {
    position: absolute;
    top: 24px;
    left: 52px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 204px;
    padding: 2px 12px;
}

.UserName {
    line-height: 16px;
    margin-bottom: 4px;
    color: var(--black-80);
    font-size: 12px;
}

.UserPosition {
    line-height: 16px;
    color: var(--black-100);
    font-weight: 500;
    font-size: 12px;
}

.UserDropdown {
    position: absolute;
    top: 50%;
    right: 28px;
}

.UserMenu {
    position: absolute;
    bottom: 20px;
    left: 270px;
    list-style: none;
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    width: 256px;
    padding: 8px 0;
    margin: 0;
    background-color: var(--white-color);
    height: auto;
    display: none;
    animation: growDown 250ms ease-in-out forwards;
    transform-origin: top center;
    z-index: 99999;
}

.isCollapsed .UserMenu {
    left: 67px;
}

.UserMenu li:hover {
    background-color: rgba(var(--primary-color-rgb), 0.04);
}

.UserMenu li a {
    display: block;
    padding: 11px 16px;
    line-height: 18px;
    color: var(--black-80);
}

.UserMenu li a :global(.anticon) {
    padding-right: 10px;
}

.UserMenu li:hover a {
    color: var(--primary-color);
}

.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
}

.SubMenuWrapper {
    z-index: 1;
    width: inherit;
    padding-left: 53px;
    transition: none;
}

.SubMenuHeader {
    color: var(--black-100);
    padding: 32px 16px;
}

.SubMenuWrapper .MenuItem a {
    padding-left: 16px;
    line-height: 48px;
    display: block;
    color: var(--black-100);
}

.SubMenuWrapper .MenuItem:hover a {
    color: var(--primary-color);
}
.user_title {
    display: block;
    position: absolute;
}
.arrow_down_icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex: 1;
    position: relative;
    top: 10px;
}
