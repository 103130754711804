.InputNumberWrapper {
    margin: 12px 16px;
}

.InputNumber {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    width: 108px;
}

.InputNumber:first-child {
    margin-right: 8px;
}

.InputNumber :global(.ant-input-number-input) {
    height: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.InputNumber :global(.ant-input-number-handler-wrap) {
    margin-right: 8px;
    border: none;
}

.InputNumber :global(.ant-input-number-handler-down) {
    border: none;
}