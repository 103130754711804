.list {
    margin: 0 25px 0 25px;
    padding-bottom: 3em;
    border-radius: 8px;
    box-shadow: none;
}
.list > :global(.t-card-container) {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.title {
    color: var(--black-100);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
}
.list_top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 24px 0 24px;
}
.button_list {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    margin-right: 40px;
    align-self: center;
}
.list :global(.ant-table-row) {
    cursor: pointer;
}