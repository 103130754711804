.button_list {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--black-100);
    border-radius: 4px;
    background: var(--black-10);
    border: 1px solid var(--black-40);
}
.button {
    border-radius: 8px !important;
}
button:nth-child(1) {
    margin-right: 12px;
}
.active,
.button_list:hover,
.button_list:active,
.button_list:focus {
    color: var(--white-color);
    border-color: var(--primary-color);
    background: var(--primary-color);
}
.hide {
    display: none;
}
.show {
    display: inline-block;
}
