.statistic_list {
    margin: 25px;
    height: 202px;
}
.statistic_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_first {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.header_first p:nth-child(1) {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    padding-right: 10px;
}
.header_first p:nth-child(2) {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
}
.header_second p:nth-child(1) {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: var(--primary-color);
}
.statistic_base_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: -25px 0;
}
.statistic_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    flex-direction: row;
    width: 100%;
}
.circle_diagram {
    margin: 25px 20px;
}
.circle_diagram_text p:nth-child(1) {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: var(--black-100);
}
.circle_diagram_text p svg {
    margin-left: 10px;
}
.customer_count {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: var(--black-60);
    letter-spacing: 0.1px;
}
.horizontal_divider {
    border: 1px solid var(--black-10);
    margin: 22px 0;
    width: 100%;
}
.vertical_divider {
    height: 9em;
    margin-bottom: 10px;
    border: 1px solid var(--black-10);
}
