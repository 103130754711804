.breakdown_content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin: 35px;
    text-align: center;
}
.breakdown_content > svg {
    width: 10em;
    height: 5em;
    padding: 0 15px 10px 0;
    margin-bottom: 10px;
    overflow: inherit !important;
}
.breakdown_container h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: var(--black-100);
    letter-spacing: 0.2px;
    margin: 15px;
}
.breakdown_container h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    padding-top: 33px;
}
.breakdown_container > :global(.t-card-container) {
    padding-right: 0 !important;
    padding-left: 0 !important;
}