@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
    --primary-color: #006ad7;
    --primary-color-rgb: 0, 106, 215;
    --error-color: #fb4e4e;
    --warning-color: #f6a609;
    --success-color: #2ac769;
    --accent-color: #ffe500;
    --background-color: #f9f9fa;
    --text-color: #25282b;
    --white-color: #ffffff;
    --black-10: #e8e8e8;
    --black-20: #DBDDE0;
    --black-40: #cacccf;
    --black-60: #a0a4a8;
    --black-80: #52575c;
    --black-100: #25282b;
    --primary-10: #cad4df;
}

*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    background-color: var(--background-color);
    font-family: "Montserrat", sans-serif;
    height: 100vh;
}

/* Vulnerability status text color */
.default_color {
    color: var(--primary-color);
}
.not_vulnerable {
    color: var(--success-color);
}
.potentially_vulnerable {
    color: var(--warning-color);
}
.vulnerable {
    color: var(--error-color);
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
    border-color: var(--primary-color) !important;
    box-shadow: none !important;
}

.ant-picker:hover {
    border-color: var(--primary-color);
}
.ant-table-pagination.ant-pagination {
    margin: 16px 16px;
}
