.container {
    /* border-bottom: 1px solid #a0a4a8; */
    padding-top: 20px;
}
.title {
    font-size: 26px;
    margin-bottom: 20px;
}
.divider {
    border-top: 1px solid #a0a4a8;
}
