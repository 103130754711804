.container {
    max-width: 540px;
    margin: 0 auto;
    border-radius: 8px;
    margin-top: 160px;
    margin-bottom: 50px;
}
.header {
    padding: 24px 0;
    background-color: var(--primary-color);
    border-radius: 8px 8px 0 0;
    text-align: center;
}
.buttonWrapper {
    margin-bottom: 24px;
}
.button {
    height: 48px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--primary-color);
}
.forgotPasswordWrapper {
    padding: 0 95px;
}
.forgotPasswordContainer {
    text-align: center;
}
.forgotPassword {
    color: var(--primary-color);
    text-align: center;
    cursor: pointer;
}
.container :global .ant-tabs-nav-list {
    width: 100%;
}
.container :global .ant-tabs-content-holder {
    padding: 0 95px;
}
.container :global .ant-tabs-nav-list .ant-tabs-ink-bar {
    background-color: var(--primary-color);
}
.container :global .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color);
}
.container :global .ant-tabs-tab {
    width: 100%;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
}
.container :global .ant-tabs-nav-operations,
.container :global .ant-tabs-nav-operations-hidden {
    width: 0;
    display: none;
}
.container :global .t-tabs-container .t-tabs .ant-tabs-nav {
    padding-left: 0;
    padding-right: 0;
}
.container :global .ant-form-item-label > label {
    color: var(--black-60);
}
.container
    :global
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.verifyEmailWrapper {
    text-align: center;
    padding: 32px 95px 24px;
}
.text {
    color: var(--black-80);
    text-align: left;
    margin: 24px 0;
}
.container :global .ant-form-item-extra {
    position: absolute;
    bottom: -25px;
}
