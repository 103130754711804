.empty_space {
    display: flex;
    justify-content: center;
    align-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 400px;
}
.empty_space svg {
    margin-bottom: 15px;
    width: auto;
    height: 3rem;
}
.empty_space span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--black-100);
}
