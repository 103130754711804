.customers_dashboard {
    margin: 25px;
}
.button {
    margin: 0 32px;
    border-radius: 4px;
    background-color: var(--primary-color);
    height: 34px;
    width: auto;
}
.button span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--white-color);
}
