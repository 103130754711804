.diagram_button,
.order_button {
    width: 68px;
    height: 34px;
    border-radius: 1px solid var(--black-10) !important;
    background-color: var(--background-color) !important;
    text-align: center;
    vertical-align: middle;
}
.diagram_button {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}
.order_button {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.diagram_button:hover,
.order_button:hover {
    background: var(--white-color) !important;
    fill: var(--primary-color) !important;
}
label[class~='ant-radio-button-wrapper-checked'] {
    border-color: var(--black-10) !important;
}
label.diagram_button > span[class~="ant-radio-button-checked"] {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    background: var(--white-color) !important;
}
label.order_button > span[class~="ant-radio-button-checked"] {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    background: var(--white-color) !important;
}
label.diagram_button > span[class~="ant-radio-button-checked"] + span svg,
label.order_button > span[class~="ant-radio-button-checked"] + span svg {
    fill: var(--primary-color) !important;
}
