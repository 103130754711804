.breadcrumb_container {
    margin-bottom: 12px;
    margin-top: 12px;
    position: relative;
    top: 0;
    margin-left: 15px;
}
.breadcrumb_item a {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--black-60);
}
.breadcrumb_item a:hover {
    color: var(--primary-color);
}
.breadcrumb_link {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--black-60) !important;
    margin-right: -12px !important;
}
.breadcrumb_container > span:last-of-type .breadcrumb_link{
    color: var(--primary-color) !important;
}