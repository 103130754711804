.Select {
    background: var(--white-color);
    /*background: red;*/
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 12px 16px;
    width: 224px;
}

.Select :global(.ant-select-selector) {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-color);
}


.Select :global(.ant-select-open .ant-select-selector),
.Select :global(.ant-select-selector:active) {
    border: none !important;
}

.Select:global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
    padding: 5px 16px;
    height: auto;
    border: none;
}

.Dropdown {
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    padding: 8px 0;
    z-index: 99999;
}

.Dropdown :global(.ant-select-item-option) {
    padding: 11px 16px;
}

.Dropdown :global(.ant-select-item-option-content) {
    line-height: 18px;
}
