.DatePicker {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    padding-right: 16px;
    margin: 12px 16px;
    width: 224px;
}

.DatePicker input {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    color: var(--primary-color);
}

.Dropdown {
    z-index: 99999;
}

.Dropdown :global(.ant-picker-date-panel) {
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    padding: 16px;
    margin: 0;
    background-color: var(--white-color);
    position: absolute;
    left: 240px;
    top: -48px;
    width: 368px;
    height: auto;
}

.Dropdown :global(.ant-picker-header) {
    border-bottom: none;
}

.Dropdown :global(.ant-picker-content) {
    width: 100%;
}

.Dropdown :global(.ant-picker-content th) {
    font-size: 12px;
    line-height: 16px;
    color: var(--black-60);
}

.Dropdown :global(.ant-picker-date-panel .ant-picker-body) {
    padding: 0;
}

.Dropdown :global(.ant-picker-cell) {
    height: 48px;
    width: 48px;
}

.Dropdown :global(.ant-picker-cell-selected) {
    background-color: var(--primary-color);
    border-radius: 8px;
}

.Dropdown :global(.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: var(--primary-color);
}

.Dropdown :global(.ant-picker-cell .ant-picker-cell-inner) {
    font-size: 14px;
    line-height: 18px;
}

.Dropdown :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
    top: -13px;
    left: -13px;
    height: 48px;
    width: 48px;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
}