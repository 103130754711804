.modal_dialog {
    width: 394px !important;
    height: 202px !important;
}
.modal_dialog > .ant-modal-content {
    background: #FFFFFF;
    box-shadow: 0 12px 40px rgba(37, 40, 43, 0.32);
    border-radius: 8px !important;
}
.ant-modal-confirm-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.ant-modal-confirm-body  svg {
    position: relative;
    top: 3px;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
    display: block;
    padding-left: 14px;
    font-size: 20px;
    font-weight: normal;
    line-height: 26px;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
}
.ant-modal-confirm-btns button {
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    width: auto;
}
.ant-modal-confirm-btns button:nth-child(1) {
    color: var(--black-60);
}
.ant-modal-confirm-btns button:hover:nth-child(1) {
    color: var(--primary-color);
}
.ant-modal-confirm-btns button:nth-child(2) {
    background: var(--primary-color);
    background-color: var(--primary-color);
}
.ant-modal-confirm-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: var(--black-80);
}
.text_color {
    display: block;
    color: var(--primary-color);
}
.global_error_dialog .ant-modal-confirm-btns button {
    color: var(--white-color);
    background: var(--primary-color);
    background-color: var(--primary-color);
}
.global_error_dialog .ant-modal-confirm-btns span {
    color: var(--white-color);
}

/* Upload unsuccessful dialog */
.reason_list  {
    margin-top: -15px;
    margin-bottom: 15px;
    color: var(--black-80);
    list-style-type: none;
}
.reason_list > li:before {
    content:"- ";
}
.guide_label {
    color: var(--primary-color);
}
.alert .ant-modal-confirm-content {
    flex-basis: 100%;
}
.alert .ant-modal-confirm-body {
    display: flex;
    flex-wrap: wrap;
}
.alert .ant-btn-primary {
    background: var(--primary-color);
}
.alert .ant-modal-confirm-btns button:hover,
.alert .ant-modal-confirm-btns button {
    color: var(--white-color);
}

/* Delete file confirmation */
.delete_file .add_btn {
    width: 99px;
    height: 40px;
}
button.ant-btn-dangerous {
    width: 90px;
    height: 40px;
    color: var(--white-color) !important;
    background-color: var(--error-color) !important;
}
.delete_file button:nth-child(1) {
    margin-right: 0;
}
