.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 29px 32px;
}
.divider {
    border-top-color: var(--black-60);
}
.header_option {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_option > div:nth-child(1) {
    margin-bottom: 0;
}
