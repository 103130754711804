.Layout {
    display: flex;
    flex-wrap: wrap;
}

.Content {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 50%;
    min-height: 100vh;
}
