.button {
    background: var(--primary-color);
    background-color: var(--primary-color);
    border-radius: 8px;
    height: 48px;
    width: 167px;
}
.button > span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
}
.button > svg {
    margin-left: 6px;
    fill: white;
    height: 20px;
    width: 20px;
}
