.event_btn {
    margin-left: -15px;
    margin-top: -5px;
    margin-bottom: -4px;
}
.horizontal_divider {
    border: 1px solid var(--black-10);
    margin: 0;
    width: 100%;
}
.month_table :global(.ant-table-tbody) :global(tr.ant-table-row:hover) :global(td) {
    background-color: var(--primary-10);
}
.activeRow {
    background-color: var(--primary-10);
}
.month_table :global(.ant-table-row) {
    cursor: pointer;
}