.popup_text {
    display: flex;
    justify-content: center;
    flex: 1;
    width: 260px;
}
.popup_text p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: var(--black-80);
    text-align: start;
}
.popup_text svg {
    width: 60px;
    padding-right: 13px;
}