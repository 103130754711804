.DropdownMenu {
    display: inline-block;
    padding: 13px 24px;
    position: relative;
}

.DropdownLabel {
    display: inline-block;
    color: var(--primary-color) !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    margin-left: 25px;
    cursor: pointer;
    position: relative;
    z-index: 99999;
}

.DropdownLabel svg {
    width: 18px;
    height: 18px;
}

.DropdownMenu ul {
    position: absolute;
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    list-style: none;
    border-radius: 8px;
    width: 256px;
    padding: 8px 0;
    margin: 0;
    background-color: var(--white-color);
    height: auto;
    display: none;
    animation: growDown 250ms ease-in-out forwards;
    transform-origin: top center;
    z-index: 99999;
}

.DropdownMenu > ul {
    top: 48px;
    left: 25px;
    width: 256px;
}

.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.DropdownMenu li {
    position: relative;
}

.DropdownMenu :global(.ant-radio-group) {
    display: block;
}

.DropdownMenu li :global(.ant-radio-wrapper),
.DropdownMenu li a {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: var(--black-80);
    margin: 0;
    padding: 11px 16px;
}

.DropdownMenu :global(.ant-radio-wrapper:hover),
.DropdownMenu li a:hover {
    color: var(--primary-color);
}

.DropdownMenu li:hover {
    background-color: rgba(var(--primary-color-rgb), 0.04);
}

.DropdownMenu > ul ul {
    left: 256px;
    top: -8px;
}

.DropdownMenu :global(.ant-radio-inner) {
    height: 20px;
    width: 20px;
    border: 2px solid var(--black-60);
    background-color: transparent;
}

.DropdownMenu :global(.ant-radio-inner::after) {
    height: 10px;
    width: 10px;
    border: 0;
    background-color: var(--primary-color);
}

.DropdownMenu :global(.ant-radio-checked .ant-radio-inner) {
    border-color: var(--primary-color);
}

.DropdownMenu :global(.ant-radio:hover .ant-radio-inner) {
    border-color: var(--black-60);
}

.DropdownMenu :global(.ant-radio-checked:hover .ant-radio-inner) {
    border-color: var(--primary-color);
}

.DropdownTag {
    background-color: var(--black-10);
    border: 1px solid var(--black-40);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 16px;
}

.DropdownTag:global(.numberOfAccountsTag) {
    margin-left: 12px;
}

.DropdownTag:global(.ant-tag) {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
}

/* Animations */
@-moz-keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}
@-webkit-keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}
@-o-keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}
@keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}
