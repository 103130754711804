.graph_list {
    min-height: 465px;
    margin: 0 1em 0 1em;
}
.content_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    flex-wrap: nowrap;
    min-height: 400px;
}
.y_axis {
    display: inline-block;
    position: relative;
    transform: translate(-43%, 14em);
    margin: 0;
    z-index: 1;
}
.y_axis_text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--black-100);
    transform: rotate(-90deg);
    margin-left: 0;
}

/* Legend customisation */
.legend_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 4em 0;
}
.legend {
    color: var(--black-80);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    display: inline-block;
    margin: 0 -16%;
}
.not_vulnerable {
    margin-bottom: 12px;
    width: 200px;
    border: 1px solid var(--success-color);
}
.potentially_vulnerable  {
    margin-bottom: 12px;
    width: 200px;
    border: 1px solid var(--warning-color);
}
.vulnerable {
    margin-bottom: 12px;
    width: 200px;
    border: 1px solid var(--error-color);
}
/* Hide xaxis all values */
:global(.apexcharts-xaxis-texts-g text[id^='SvgjsText']) {
    display: none;
}
/* Revert xaxis first and last values */
:global(.apexcharts-xaxis-texts-g text[id^='SvgjsText']):nth-of-type(1),
:global(.apexcharts-xaxis-texts-g text[id^='SvgjsText']):nth-last-of-type(1) {
    display: revert;
}
