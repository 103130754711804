.section {
    width: 1110px;
    height: 562px;
    display: grid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.container {
    height: 562px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: space-around;
    justify-content: space-around;
    background-color: var(--white-color);
    border-radius: 8px;
    -webkit-box-shadow: 0 0 4px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 0 4px 0 rgba(34, 60, 80, 0.2);
}
.text_content {
    width: 350px;
}
.title_first,
.title_second,
.error_code {
    letter-spacing: 0.1px;
    color: var(--primary-color);
    font-style: normal;
}
.title_first {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
}
.title_second,
.error_code {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}
.authenticated {
    display: grid;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1110px;
    height: 562px;
    margin: auto;
    -ms-transform: translateX(-40%) translateY(-50%);
    -webkit-transform: translate(-40%,-50%);
    transform: translate(-40%,-50%);
}
.top_logo {
    display: flex;
    position: relative;
    top: -5em;
    flex-wrap: wrap;
    justify-content: start;
    align-content: space-between;
}
.top_logo img {
    height: 35px;
    margin-top: -5px;
}
.logo_text {
    padding-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 26px;
    color: var(--primary-color);
}
.go_home_button {
    background: var(--primary-color);
    border-radius: 4px;
    height: 40px;
    width: 350px;
    left: 0;
    top: 0;
}
.go_home_button a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--white-color);
}
